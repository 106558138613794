<template>
  <div class="goto-lesson" @click="onGotoCourseware">
    进入课件
  </div>
</template>

<script>
import {defineComponent} from "vue";
import {useRoute, useRouter} from "vue-router";

export default defineComponent({
  name: "GotoLesson",
  data() {
    return {}
  },
  setup(){
    const router = useRouter();
    const route = useRoute();
    const onGotoCourseware = async ()=> {
      if (route.query.from === 'courseware'){
        await router.back();
        return;
      }
      await router.replace({
        name: 'Courseware',
        query: route.query,
      })
    }

    return {
      onGotoCourseware,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
</script>

<style lang="less" scoped>
.goto-lesson {
  position: absolute;
  bottom: 36px;
  right: 36px;
  background: #F38228;
  border-radius: 34px;
  border: 3px solid #FFFFFF;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  padding: 7px 35px;
  cursor: pointer;
}
</style>
