import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "homework" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { style: {"font-size":"30px"} }
const _hoisted_4 = { class: "content scroll-bar-hidden" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_timetable_title = _resolveComponent("timetable-title")!
  const _component_logout_icon = _resolveComponent("logout-icon")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_dw_rate = _resolveComponent("dw-rate")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_image_preview_group = _resolveComponent("a-image-preview-group")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_goto_lesson = _resolveComponent("goto-lesson")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_timetable_title, null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("上节作业讲评")
        ])),
        _: 1
      }),
      _createVNode(_component_logout_icon)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, "第" + _toDisplayString(_ctx.currentClass) + "次课：" + _toDisplayString(_ctx.topicName), 1),
      _createElementVNode("div", _hoisted_3, "作业名称：" + _toDisplayString(_ctx.workTitle), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_a_row, {
        type: "flex",
        align: "middle"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_image_preview_group, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.homeworkList, (i, k) => {
                return (_openBlock(), _createBlock(_component_a_col, {
                  span: 6,
                  key: i.id
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_a_image, {
                        class: "img",
                        src: i.homeworkUrl
                      }, null, 8, ["src"]),
                      _createVNode(_component_dw_rate, {
                        value: i.star,
                        "onUpdate:value": ($event: any) => ((i.star) = $event)
                      }, null, 8, ["value", "onUpdate:value"]),
                      _createElementVNode("div", _hoisted_6, _toDisplayString(i.name), 1)
                    ])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_goto_lesson)
  ]))
}